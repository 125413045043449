
import { db } from '@/firebase/firebaseDB'
const orders = db.collection('orders');






export const getOrderById = (callback, id) => {
  orders
    .where('userId', '==', id)
    .onSnapshot((querySnapshot) => {
      const orderList = [];
      querySnapshot.forEach((doc) => {
        const order = doc.data();
        orderList.push(order);
      });
      callback(orderList);
    });
};


export const getOrders = (callback) => {  
  return orders.onSnapshot((snapshot) => {
    const orderList = snapshot.docs.map((doc) => doc.data());
    callback(orderList);
  });
};

export const getSingleOrderById = (callback, id) => {
  return orders.doc(`${id}`).onSnapshot((snapshot) => {
    const singleOrder = snapshot.data()
    callback(singleOrder);
  });
};



export const createOrder = async (data) => {
    data.id =  orders.doc().id;
    orders.doc(data.id).set(data)
}

export const updateOrder = (id, data) => {

  return orders.doc(id).update(data);


};