
const config={
   ordersColumns: [
    {
        title: 'Producto',
        dataIndex: 'productName',
        scopedSlots: { customRender: 'productName' },
    },
    {
        title: 'ESTATUS',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
    },
    {
        title: 'FECHA DE CREACIÓN',
        dataIndex: 'dateCreated',
        scopedSlots: { customRender: 'dateCreated' },
    },
    {
        title: '',
        scopedSlots: { customRender: 'editBtn' },
        width: 50,
    },

]
};

export default config
