	
<template>
	<div>
    <a-breadcrumb class="mb-10">
			<a-breadcrumb-item><router-link to="/orders"> Inicio / Ordenes</router-link></a-breadcrumb-item>
			<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
		</a-breadcrumb>
        <CardOrderAdmin
		:data="order"
        @updateOrder="updateOrder"
		></CardOrderAdmin>
	</div>
</template>

<script>
import CardOrderAdmin from '@/components/Cards/CardOrderAdmin'
import { getSingleOrderById,updateOrder } from '@/services/orders/orders';
import  config   from '@/utils/table.config'
	// "Authors" table component.

	export default ({
		components: {
            CardOrderAdmin
		},
		data() {
			return {
				order:null,
			}
		},
		created(){
			this.getOrder()
		},
		methods:{
			async getOrder(){
				await getSingleOrderById((data)=>{
					
					this.order=data
				},this.$route.params.id)
			},
           async updateOrder(val){
               

                updateOrder(this.$route.params.id,val)
            }
		}
	})
</script>

<style lang="scss">
</style>

